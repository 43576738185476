<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6" lg="5">
        <v-sheet
          color="white"
          elevation="4"
          outlined
          rounded
          align="center"
          class="pa-5 mb-12 rounded-lg"
        >
          <h6 class="text-h6 mb-6">Ubah Kata Sandi</h6>
          <v-form id="changePassword" ref="changePassword" :model="form" :rules="rules" @submit.native.prevent="changePassword">
            <v-text-field
              ref="oldPassword"
              v-model="form.oldPassword"
              class="mb-5"
              dense
              :label="$_strings.welcomePage.OLD_PASSWORD"
              :type="oldPasswordShow ? 'text' : 'password'"
              :append-icon="oldPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="oldPasswordShow = !oldPasswordShow"
            ></v-text-field>
            <v-text-field
              ref="newPassword"
              v-model="form.newPassword"
              class="mb-5"
              dense
              :label="$_strings.welcomePage.NEW_PASSWORD"
              :type="newPasswordShow ? 'text' : 'password'"
              :rules="rules.newPassword"
              :append-icon="newPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="newPasswordShow = !newPasswordShow"
            ></v-text-field>
            <v-text-field
              ref="confirmPassword"
              v-model="form.confirmPassword"
              class="mb-5"
              dense
              :label="$_strings.welcomePage.NEW_CONFIRM_PASSWORD"
              :type="confirmPasswordShow ? 'text' : 'password'"
              :rules="rules.confirmPassword"
              :append-icon="confirmPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="confirmPasswordShow = !confirmPasswordShow"
            ></v-text-field>
            <vue-recaptcha
              ref="recaptcha"
              @verify="onVerify"
              @expired="onExpire"
              @error="onError"
              class="mb-2"
              align="center"
              sitekey="6LcWxG0bAAAAAHkgsiZ-MNzH6ABcNQO0tbGhCIQE">
            </vue-recaptcha>
            <v-btn rounded large class="background-denim white--text font-weight-bold body-2 w-100" type="submit" :disabled="!verify">
              {{$_strings.welcomePage.CHANGE_PASSWORD}}
            </v-btn>
          </v-form>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: 'change-password',
  components: {
    VueRecaptcha,
  },
  mounted() {
  },
  data() {
    return {
      form: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      oldPasswordShow: false,
      newPasswordShow: false,
      confirmPasswordShow: false,
      rules: {
        newPassword: [
          (v) => {
            if (v) {
              const upperCaseLetters = /[A-Z]/g;
              const numbers = /[0-9]/g;
              const symbol = /[$-/:-?{-~!"^_`\\[\]\s@]/;
              if ((!symbol.test(v) || v.includes(' ') || !v.match(upperCaseLetters)) || (!v.match(numbers)) || (v.length < 6)) {
                return 'Password wajib alphanumeric, kapital, simbol, tidak memiliki spasi dan minimal 6 karakter';
              }
              return !!v;
            }
            return this.$_strings.messages.validation.REQUIRED('Kata Sandi Baru');
          },
        ],
        confirmPassword: [
          (v) => {
            if (v !== this.form.newPassword) {
              return this.$_strings.messages.validation.PASSWORD_NOT_MATCH;
            }
            if (!v) {
              return this.$_strings.messages.validation.REQUIRED('Ulang Kata Sandi');
            }
            return true;
          },
        ],
      },
      // captcha
      verified: false,
      expired: false,
      tokenCaptcha: null,
    };
  },
  computed: {
    verify() {
      return this.verified;
    },
  },
  methods: {
    changePassword() {
      const valid = this.$refs.changePassword.validate();
      if (valid && this.tokenCaptcha) {
        this.$root.$loading.show();
        this.$_services.account.setChangePassword(this.form)
          .then((result) => {
            this.$root.$loading.hide();
            this.$dialog.notify.success(this.$_strings.welcomePage.SUCCESS_CHANGE_PASSWORD);
            this.$router.go();
          })
          .catch((err) => {
            this.$root.$loading.hide();
          });
      }
    },
    onVerify(token, ekey) {
      this.verified = true;
      this.tokenCaptcha = token;
      this.eKey = ekey;
    },
    onExpire() {
      this.verified = false;
      this.tokenCaptcha = null;
      this.eKey = null;
      this.expired = true;
    },
    onError(err) {
      this.tokenCaptcha = null;
      this.eKey = null;
      this.error = err;
    },
  },
};
</script>

<style lang="scss" scoped>
section .v-footer {
  background: none !important;
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>
